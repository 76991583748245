@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide the progress bar. `display:none` causes the toast to never dismiss. */
.Toastify__progress-bar {
  opacity: 0 !important;
}

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom, 2rem);
  }

  .webkit-highlight-transparent {
    -webkit-tap-highlight-color: transparent;
  }

  .no-scroll-bars::-webkit-scrollbar {
    display: none;
  }
}

html,
body {
  @apply h-full select-none bg-neutral-950 text-neutral-300 antialiased md:select-auto;
  -webkit-touch-callout: none;
}

a {
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

@keyframes scroll-text {
  0% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(0%);
  }
  80% {
    transform: translateX(var(--scroll-to));
  }
  100% {
    transform: translateX(var(--scroll-to));
  }
}

.animate-scroll-text {
  animation: scroll-text var(--scroll-time) linear infinite alternate;
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}
